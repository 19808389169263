@import "src/assets/styles/mixins";

@include keyframes(spinnerLoading) {
  0% {
    opacity: .6;
    transform: rotate(0deg);
  }

  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

@include keyframes(scaleSlideInDown) {
  0% {
    opacity: .6;
    transform: scale(.8) translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@include keyframes(cardAnimation) {
  0% {
    opacity: 0;
    transform: rotateX(20deg) translateY(-100px);
  }
  50% {
    opacity: .6;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: rotateX(0) translateY(0);
  }
}

@include keyframes(notificationAnimation) {
  0% {
    opacity: .6;
    transform: rotateX(20deg) translateY(-100px);
  }
  50% {
    opacity: 1;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: rotateX(0) translateY(0);
  }
}

@include keyframes(infiniteBounce) {
  0%, 100% {
    opacity: .6;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
}

@include keyframes(infiniteBounce2) {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@include keyframes(deepScaleSlideInDown) {
  0% {
    opacity: .2;
    width: 0;
    transform: scale(.8) translateY(-20px);
  }
  50% {
    opacity: .6;
    width: 10px;
    transform: scale(.8) translateY(-120px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@include keyframes(fadeInSlideInDown) {
  0% {
    opacity: .6;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(deepFadeInSlideInDown) {
  0% {
    opacity: .2;
    height: 0;
    transform: translateY(-20px);
  }
  20% {
    opacity: .6;
    height: 60px;
    transform: translateY(-20px);
  }
  50% {
    opacity: .8;
    height: auto;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(slideInLeft) {
  0% {
    transform: translateX(-400px);
  }
  100% {
    transform: translateX(0);
  }
}

@include keyframes(slideInLeftCustom) {
  0% {
    transform: translateX(400px);
  }
  100% {
    transform: translateX(0);
  }
}

@include keyframes(slideInRight) {
  100% {
    transform: translateX(-400px);
  }
  0% {
    transform: translateX(0);
  }
}

@include keyframes(smallSlideInDown) {
  50% {
    opacity: .6;
    transform: translateY(2px);
  }
  0%, 100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(slideInRightCustom) {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(400px);
  }
}

@include keyframes(slideInDown) {
  0% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

@include keyframes(slideInTopText) {
  50% {
    //transform: translateY(8px);
    opacity: 1;
    color: #1BD895;
  }
  0%,100% {
    opacity: 0;
    color: #242424;
   // transform: translateY(0);
  }
}

@include keyframes(bigSlideInDown) {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@include keyframes(bigSlideInDownReverse) {
  0% {
    transform: translateY(0);
  }
  20% {
    opacity: .8;
  }
  50% {
    opacity: .5;
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: translateY(-20px);
  }
}

@include keyframes(slideInTop) {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0);
  }
}


@include keyframes(slideInTopWithOpactity) {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(gradient) {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@include keyframes(scaleIn) {
  0% {
    opacity: .2;
    transform: scale(1.2) translateZ(60px);
  }
  20% {
    opacity: .6;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0);
  }
}

@include keyframes(bounce) {
  0% {
    transform: scale(.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(.8);
  }
}

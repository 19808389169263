@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

* {
  box-sizing: border-box;
  font-weight: 500;
  color: #242424;
  font-family: "Montserrat", "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Montserrat", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color .3s ease;
  background-color: #ececec;
  font-size: 112.5%;
  // overflow-x: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

.firebase-emulator-warning {
  display: none;
}

.predefinedHeight {
  margin-top: $commonHeight;
}

.app-container {
  min-height: 100vh;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.icon_disabled_white {
  fill: #8181814a !important;
  cursor: not-allowed;
  display: none;
}

.icon__white, .icon__black {
  cursor: pointer;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mt-n3 {
  margin-top: -.3rem;
}

.mr-1 {
  margin-right: .5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 2rem;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.center_logo {
  width: 500px !important;
  height: 160px !important;

}

details ul {
  max-height: 200px;
  overflow-y: auto;
}

.btn-disabled {
  background: #ececec !important;
  border: 1px solid #0000001a !important;
  cursor: not-allowed !important;
  color: #cccccc;

  span {
    color: #cccccc !important;
  }

  svg {
    fill: #cccccc !important;
  }

  &:hover {
    transform: none !important;
    box-shadow: none !important;
  }
}

.isInvalid {
  border-bottom-color: $errorColorInput !important;
}

.isValid {
  border-bottom-color: #{$sitePrimaryColor}8a !important;
}

.bgWarning {
  background-color: orange !important;
}

.notification__item {
  border-radius: 12px !important;
}

.notification__item--danger {
  background-color: #F18084 !important;
  border-left: 6px solid #{$errorColor}6a !important;
}

button {
  font-size: 20px;
}

.fullWith {
  width: 100% !important;
}

.maintenance {
  background-color: #ffcc00;
  padding: .5rem;
  margin: .5rem;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
}

.center-flex {
  @include flexCenterSpace;
  justify-content: center;
}

.pictureSelected {
  border-color: $sitePrimaryColor !important;
  border-width: 3px !important;
}

.appRouteSection {
  margin-left: auto;
  background-color: #ececec;
  transition: all .3s ease;
}

@media screen and (max-width: 1300px) {
  .appRouteSection {
    width: 100% !important;
  }
}

@media screen and (max-width: $breakpoint) {
  .app-container {
    margin-top: -.6rem;
  }
  .predefinedHeight {
    margin-top: 3.6rem;
  }

  .center_logo {
    width: 300px !important;
    height: 100px !important;
  }
}

@media screen and (max-width: 400px) {
  .appRouteSection {
    margin-top: 2.7rem !important;
  }
}

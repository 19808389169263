@import "src/assets/styles/mixins";
@import "src/assets/styles/keyframes";

.notifications {
  background: red;
  min-height: 60px;
  width: 100%;
  color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  transition: all .3s ease;
  left: 0;
  top: 0;
  right: 0;
  z-index: 20002;
  box-shadow: 0 2px 4px #2424246a;
  padding: 1rem;

  &Animate {
    @include animation("notificationAnimation .7s ease");
  }

  &Reverse {
    @include animation("notificationAnimation .7s ease reverse");
  }
}

@media screen and (max-width: 412px) {
  .notifications {
    font-size: 14px;
    min-height: 40px;
    padding: .7rem 1rem;
  }
}


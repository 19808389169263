@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";
@import "src/assets/styles/keyframes";

.text {
  font-size: 16px;
  margin-bottom: 1rem;
  margin-top: .5rem;
}

.rippleLoading {
  height: calc(100vh - #{$commonHeight});
  @include flexCenterSpace;
  justify-content: center;
  flex-direction: column;

  .wrapper {
    @include flexCenterSpace;
    justify-content: center;
    margin-top: 2rem;

    .child {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 35px;
      height: 35px;
      border: 3px solid;
      border-radius: 50%;
      animation: spinnerLoading 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #12968f transparent transparent transparent;
    }

    .child:nth-child(1) {
      animation-delay: 0.2s;
    }

    .child:nth-child(2) {
      animation-delay: 0.3s;
    }

    .child:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@media screen and (max-width: $breakpoint) {
  .rippleLoading {
    span svg {
      height: 80px !important;
      width: 80px !important;
    }

    .wrapper {
      margin-top: 1rem;

      .child {
        width: 25px;
        height: 25px;
        border-width: 2px;
      }
    }

    .text {
      font-size: 14px;
    }
  }
}

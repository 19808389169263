$breakpoint: 968px;
$breakpointHD: 1200px;
$breakpointHD-2: 1368px;
$breakpoint4K: 2060px;
$commonHeight: 3.6rem;
$siteColor: #fbfbfb;
$sitePrimaryColor: #2BD1CB;
$shadowColor: #cccc;
$shadowBlackColor: #202325;
$verySmallShadow: 0 1px 2px $shadowColor;
$smallShadow: 0 2px 4px $shadowColor;
$smallBlackShadow: 0 2px 4px $shadowBlackColor;
$smallWhiteShadow: 0 2px 4px $siteColor;
$normalShadow: 0 4px 8px $shadowColor;
$bigShadow: 0 8px 16px $shadowColor;
$bigBlackShadow: 0 8px 16px $sitePrimaryColor;
$normalBlackShadow: 0 4px 8px $sitePrimaryColor;
$siteBlackColor: #202325;
$carouselBg: #f6f6f6;
$carouselBgBlack: #161616;
$errorColor: #ad0202;
$errorColorInput: #F18084;

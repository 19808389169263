@mixin flexCenterSpace() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin addShadow($type: normal,$color: #cccccc8a ,$upper:0) {
  @if ($upper == 0) {
    box-shadow: 0 map-get((
            verySmall: 1px 1px,
            small: 1px 2px,
            normal: 2px 4px,
            big: 4px 8px,
    ), $type) $color;
  } @else {
    box-shadow: 0 map-get((
            small: 1px 2px,
            normal: 2px 4px,
            big: 4px 8px,
    ), $type) $upper $color;
  }
}

@mixin positionChanged {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin bgImageStyles {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin scale3dAnimation {
  animation: scale3d .3s ease;
}

@mixin animation($value) {
  -webkit-animation: #{$value};
  -moz-animation: #{$value};
  -o-animation: #{$value};
  animation: #{$value};
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@keyframes scale3d {
  0% {
    opacity: .2;
    transform: scale(1.1) translateZ(60px);
  }
  20% {
    opacity: .6;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0);
  }
}